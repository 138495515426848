@import 'src/styles.scss';

$font-size: 16px;
$font-color: #333;
$font-weight: inherit;
$line-height: inherit;
$letter-spacing: inherit;
$textHighlightColor : #ffc705;

@font-face{
    font-family: Myriad-Pro Light;
    src: url('/assets/templates/template_007B/fonts/MyriadPro-Light.otf');
}

@font-face{
    font-family: Myriad-Pro Regular;
    src: url('/assets/templates/template_007B/fonts/MyriadPro-Regular.otf');
}

@font-face{
    font-family: Myriad-Pro Bold;
    src: url('/assets/templates/template_007B/fonts/MyriadPro-Bold.otf');
}

@font-face {
    font-family: ZapfinoForteLTPro;
    src: url('/assets/templates/template_007B/fonts/ZapfinoForteLTPro.otf');
}
.txtFieldCls{
    border: 1px solid #e1e1e1;
    font-size: 17px;
    height: 40px;
    margin:0;
    width: 70%!important;
    outline: medium none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    padding: 0 10px;
    color: #7a7a7a;
    font-size: 12px;
    background-color: #f6f6f6;
}
.txtFieldCls:focus{
    box-shadow: 0 0 8px #e1e1e1;
    background-color: white;
    border: 1px solid $textHighlightColor;
}
#suggestion-container {
    height: 100%;
    width: 100%;
    border-bottom: medium none !important;
}
.search-panel i{
    display: none;
}
input:focus {
    outline: medium none !important;
}
#suggestion-container input{
    padding-left: 10px;
    margin: 0;
    margin-top: 7px;
    height: 26px;
    background-color: white;
    font-size: 14px;
    color: #7a7a7a;
    width: calc(100% - 10px);
}
::placeholder{
    color:#7A7A7A !important;
    font-size: 12px;
}
#suggestion-container input::-webkit-input-placeholder{
    color: #7a7a7a;
}
.goPnl {
    float: right;
    width: 50px;
    font-size: 14px;
    height: 100%;
    color: white;
    cursor: pointer;
}
.blog-content-panel .content img {
    display: none ;
}
.search-panel app-search > div {
    height: 100%;
    width: 100%;
}
button:focus, a:focus {
    outline: medium none !important;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
perfect-scrollbar{
    margin-bottom: 20px !important;
    max-height: 240px !important;
    overflow: auto !important;
    height: auto !important;
}
.typeSpecFilterPanel perfect-scrollbar{
    overflow: auto !important;
    max-height: 145px !important;
}
#options-overlay {
    width: 28% !important;
    max-height: 220px !important;
}
#options-overlay a {
    font-size: 12px;
    margin: 0;
    color: #7A7A7A;
    display: inline-block;
    width: 100%;
}
.waves-effect.option div {
    padding: 7px;
}
.loginPopUp .login-component{
    height: 450px;
    width: 770px;
    max-width: 770px !important;
    margin-bottom: 0 !important;
}
.loginPopUp .heading-lbl{
    visibility: visible !important;
}
.loginPopUp .login-component{
    border: 1px solid $textHighlightColor;
}
body{
    min-height: 100%;
    height: auto !important;
}
.mat-dialog-container{
    border: medium none !important;
    box-shadow: none !important;
    padding: 0 !important;
    overflow: hidden !important;
    border-radius: 0 !important;
}
.hide{
    display: none !important;
    opacity: 0;
}
.rating-container{
    padding: 0px !important;
}
ls-suggestion{
    width: calc(100% - 50px) !important;
    display: inline-block;
    height: 100%;
}
a {
    color: #428bca;
    text-decoration: none;
    outline: medium none;
}
.static-page p {
    color: #005825;
    font-family: Myriad-Pro Regular;
    line-height: 1.9;
    font-size: 14px;
}
input.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}
textarea.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}
select.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}
.master{
    height: auto !important;
    position: relative;
}
.file-snip p{
    font-family: Myriad-Pro Regular;
    color: #005825;
    font-size: 14px;
}
.ps__rail-y {
    background-color: #e8e8e8 !important;
    width: 2px !important;
}

.ps__thumb-y {
    width: 4px !important;
    right: 0px !important;
    background-color: #aaa!important;
}
.static-page *{

}
select:focus{
    outline: none;
}
.catHeadingWrapper{
    height: 35px;
    background-color: #B3B3B3;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
    .catHeading{
        color: #000;
        font-size: 20px;
        font-weight: normal;
        background-color: $textHighlightColor;
        display: inline-block;
        width: auto;
        height: 100%;
        padding: 7px 20px;
        text-align: center;
        margin: 0;
    }
}
.spinner{
    @include container(30px, 65px);
    font-size: 12px;
    .firstDiv{
        width: 40px;
        background-color: $textHighlightColor;
        input{
            @include container(100%, 100%);
            padding: 0px 10px;
            font-size: 12px;
            color: #7a7a7a;
            background-color: #f6f6f6;
            border: 1px solid #e1e1e1;
        }
    }
    .secondDiv{
        width: 25px;
        color: white;
        cursor: pointer;
        background-color: $textHighlightColor;
        .prodQuantitySpinnerUp{
            margin-top: 2px;
        }
        .prodQuantitySpinnerDown{
            margin-bottom: 2px;
        }
    }
}
.disable{
    opacity: 0.6;
    pointer-events: none;
}
@media screen and (min-width:1024px){
    .panelClass {
        position: absolute !important;
        right: 14% !important;
        top: 45px;
    }
}
@media screen and (max-width: 1024px){
    ls-suggestion{
        width: 95% !important;
    }
    .search-panel app-search > div{
        background-color: transparent;
    }
    app-search{
        width: 100%;
    }
    #options-overlay{
        width: 80% !important;
    }
    .menu-width {
        width: 70% !important;
        height: 100vh !important;
        background-color: transparent;
        box-shadow: none !important;
    }
    .panelClass {
        position: absolute !important;
        right: 0% !important;
        top: 130px;
    }
    .static-page *{
       max-width: 100%;
       display: inline-block;
    }
}

@media screen and (max-width:767px){
    #options-overlay {
        width: 75% !important;
    }
    .static-page * {
        max-width: 100%;
        // width: 100%;
        line-height: 1.2;
        display: inline-block;
        font-size: 1em !important;
    }
    .static-page img {
        width: 100% !important;
        height: auto;
    }
    #suggestion-container input,span#lnkGo{
        color: #a0a0a0 !important;
    }
    .cdk-global-overlay-wrapper{
        background-color: rgba(0,0,0,0.7) !important;
    }
    app-order-tracking {
        padding: 0 20px;
        display: inline-block;
        width: 100%;
    }
    .mce-panel {
        width: 100% !important;
    }
    ._2p3a{
        min-width: 100% !important;
    }
    .static-page p{
        line-height: 1.5;
    }
    .typeSpecFilterPanel perfect-scrollbar{
        margin-bottom: 0 !important
    }
    .menu-width {
        width: 80% !important;
        height: 78vh !important;
        background-color: transparent;
        box-shadow: none !important;
    }
    .panelClass{
        position: static !important;
    }
}
@media screen and (min-width :1024px) and (max-width:1280px){
    .panelClass{
        right: 0 !important;
    }
}